
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { CircularProgress } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import InputAdornment from '@material-ui/core/InputAdornment';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { Component } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import axios from 'axios';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { TableRow } from '@material-ui/core';
import step1 from '../../components/FormStepper/step1avatar.svg';
import step2a from '../../components/FormStepper/step2avatar.svg';
import step2b from '../../components/FormStepper/rightpanelbg.svg';
import step3 from '../../components/FormStepper/step3avatar.svg';
import checkmark from '../../components/FormStepper/check_mark.svg';
import NumberFormat from 'react-number-format';
import './style.scss'

class FormStepperComponent extends Component {
    state = {
        time: 180,
        timer: null,
        isFetching: false,
        invalidOtp: false,
        setFormState: false,
        openDialog: false,
        hideDialog: false,
        validateOtp: false,
        merchantRegistrationSuccess: false,
        merchantRegistrationSent: false,
        otpResponseRaw: '',
        otpResponseValue: '',
        merchantResponseRaw: '',
        merchantResponseValue: '',
        openConfirmationDialog: false,
        openErrorDialog: false,
        activeStep: 0,
        steps: ['Your Company Details', 'Verify OTP', 'Registration Complete'],
        companyDetails: {
            name: '',
            email: '',
            mobile_no: '',
            company_name: '',
            nature_of_business: '',
            company_address: '',
            company_website: '',
            tin: '',
            prefix: '',
        },
        otp: '',
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.openDialog) {
            this.setState({ setFormState: false });
            this.setState({ hideDialog: false });
        }
        console.log('NEXT RECEIVE PROPS', nextProps.openDialog);
    }

    header = () => {
        const username = 'administrator';
        const password = '123@123';

        console.log('USER', username);
        console.log('PASS', password);
        const accountHash = window.btoa(username + ":" + password);
        console.log('ACOUNT HASH', accountHash);
        const summaryHeaders = {
            'accept': 'application/json',
            'content-type': 'application/json',
            'Authorization': `Basic ${accountHash}`,
            // 'Access-Control-Allow-Origin:': '*',
        };
        return summaryHeaders;
    }


    async saveOtp(value) {
        const reqHeader = this.header();
        console.log('REQ HEADER', reqHeader);
        console.log('VALUE', value);
        const mandrakeOTPUrl = 'https://workflow.payserv.net/tesseract/sandboxotps';
        // const mandrakeOTPUrl = 'http://localhost:3004/tesseract/sandboxotps';
        const sendOTP = await axios({
            method: 'post',
            url: mandrakeOTPUrl,
            headers: reqHeader,
            data: value
        }).then(async (response) => {
            console.log('RESPONSE', response);
            return await response;
        }).catch((error) => {
            console.log('ERROR ON SANDBOX OTP GET AUTHORIZE', error);
            return error;
        });
        this.state.otpResponseRaw = sendOTP;

        // this is for the timer
        console.log('TIMER', this.state.timer);
        this.state.timer = setInterval(() => this.setState({
            time: this.state.time - 1,
        }), 1000);
    }

    async resendOtp(value) {
        const reqHeader = this.header();
        console.log('REQ HEADER', reqHeader);
        console.log('VALUE', value);
        // const mandrakeUpdateOTPUrl = 'http://localhost:3004/tesseract/sandboxotps/otp/mobile';
        const mandrakeUpdateOTPUrl = 'https://workflow.payserv.net/tesseract/sandboxotps/otp/mobile/';
        const updateOTP = await axios({
            method: 'put',
            url: mandrakeUpdateOTPUrl,
            headers: reqHeader,
            data: value
        }).then(async (response) => {
            console.log('RESPONSE', response);
            return await response;
        }).catch((error) => {
            console.log('ERROR ON SANDBOX OTP GET AUTHORIZE', error);
            return error;
        });
        this.state.otpResponseRaw = updateOTP;
        // this is for the timer
        console.log('TIMER SHOULD RUN');
        this.state.timer = setInterval(() => this.setState({
            time: this.state.time - 1,
        }), 1000);
    }



    async saveMerchant(value) {
        const reqHeader = this.header();
        this.setState({isFetching: true})
        console.log('REQ HEADER', reqHeader);
        // const mandrakeMerchantURL = `${reqHeader+'sandboxmerchants'}`;
        const mandrakeMerchantURL = 'https://workflow.payserv.net/tesseract/sandboxmerchants';
        // const mandrakeMerchantURL = 'http://localhost:3004/tesseract/sandboxmerchants';
        const saveMerchantDetails = await axios({
            method: 'post',
            url: mandrakeMerchantURL,
            headers: reqHeader,
            data: value
        }).then(async (response) => {
            console.log('MERCHANT REGISTRATION RESPONSE', response);
            if (this.state.merchantRegistrationSent) {
                this.setState({merchantRegistrationSuccess: true})
                this.setState({isFetching: false})
            }
            return await response;
        }).catch((error) => {
            console.log('ERROR ON MERCHANT REGISTRATION', error);
            if (this.state.merchantRegistrationSent) {
                this.setState({merchantRegistrationSuccess: false})
                this.setState({isFetching: false})
            }
            return null;
        });
        this.state.merchantResponseRaw = saveMerchantDetails;
        console.log('MERCHANT VALUES', this.state.merchantResponseRaw);
        return saveMerchantDetails;
    }

    onReset = () => {
        console.log('RESETING DATAS');
        this.setState({
            companyDetails: {
                name: '',
                email: '',
                mobile_no: '',
                company_name: '',
                nature_of_business: '',
                company_address: '',
                company_website: '',
                tin: '',
                prefix: '',
            },
            otp: '',
            setFormState: false,
            openDialog: false,
            hideDialog: true,
            validateOtp: false,
            invalidOtp: false,
            isFetching: false,
            merchantRegistrationSuccess: false,
            merchantRegistrationSent: false,
            time: 180,
            timer: null,
        });
        clearInterval(this.state.timer);
    }

    showDialog = () => {
        window.history.pushState({}, "SIGNUP", "/signup");
        this.setState({ setFormState: true });
        this.setState({ activeStep: 0 });
    }

    hideDialog = () => {
        console.log('HIDING DIALOG');
        window.history.pushState({}, "HOME", "/");
        this.onReset();
    }

    hideConfirmDialog = () => {
        this.setState({ openConfirmationDialog: false });
    }

    handleNext = () => {
        console.log('BEFORE ACTIVE STEP', this.state.activeStep);
        this.setState({ openConfirmationDialog: false });
        // this.setState({ activeStep: this.state.activeStep + 1 });
        if (this.state.activeStep === 0) {
            this.setState({ activeStep: this.state.activeStep + 1 });
            const mobileToSend = {
                mobile_no: this.state.companyDetails.mobile_no,
            };
            this.saveOtp(mobileToSend);
        }
        else if (this.state.activeStep === 1) {
            const otpResponse = this.state.otpResponseRaw ? this.state.otpResponseRaw.data : '';
            this.setState({ otpResponseValue: otpResponse })
            this.setState({ validateOtp: true })
            console.log('OTP RESPONSE', otpResponse);
            console.log('OTP VALUE', this.state.otp);
            const companyDetails = this.state.companyDetails;
            console.log('COMPANY VALUES', companyDetails);
            console.log('CAN SAVE MERCHANT 1', otpResponse !== null && companyDetails.mobile_no === otpResponse.mobileNo && this.state.otp === otpResponse.otp);
            console.log('CAN SAVE MERCHANT 2', companyDetails.mobile_no === otpResponse.mobileNo);
            console.log('CAN SAVE MERCHANT 3', this.state.otp === otpResponse.otp);
            console.log('CAN SAVE MERCHANT 4', this.state.activeStep === 1);
            console.log('CAN SAVE MERCHANT 5', otpResponse !== null);
            if (otpResponse !== null && companyDetails.mobile_no === otpResponse.mobileNo && this.state.otp === otpResponse.otp && this.state.otp.length === 6) {
                console.log('SAVING COMPANY FORM DATA');
                const newCompanyDetails = {
                    name: companyDetails ? companyDetails.name.trim() : '',
                    email: companyDetails ? companyDetails.email.trim() : '',
                    mobile_no: companyDetails ? companyDetails.mobile_no.trim() : '',
                    company_name: companyDetails ? companyDetails.company_name.trim() : '',
                    nature_of_business: companyDetails ? companyDetails.nature_of_business.trim() : '',
                    company_address: companyDetails ? companyDetails.company_address.trim() : '',
                    company_website: companyDetails ? companyDetails.company_website.trim() : '',
                    tin: companyDetails ? companyDetails.tin.trim() : '',
                    prefix: companyDetails ? companyDetails.prefix.trim() : "",
                }
                this.saveMerchant(newCompanyDetails);
                this.setState({merchantRegistrationSent: true})
            } else {
                this.setState({ invalidOtp: true });
            }
        }

    };

    async proceed(checkData) {
        await this.saveMerchant(checkData);

        const companyDetails = this.state.companyDetails;
        // eslint-disable-next-line
        const emailLegit = companyDetails ? companyDetails.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim) : '';

        const tinValidation = companyDetails && companyDetails.tin.length !== 15;
        console.log('VALIDATION 1', tinValidation);

        const mobileValidation = companyDetails && companyDetails.mobile_no.substring(0, 3) !== '639';
        console.log('VALIDATION 2', mobileValidation);
        const mobileValidation2 = companyDetails && companyDetails.mobile_no.length !== 12;
        console.log('VALIDATION 3', mobileValidation2);

        const emailValidation = emailLegit !== null;
        console.log('VALIDATION 4', emailValidation);

        const merchantResponse = this.state.merchantResponseRaw ? this.state.merchantResponseRaw.data : null;
        console.log('MERCHANT RESP VALUE', merchantResponse);
        console.log('VALIDATION 6', this.state.merchantResponseRaw ? merchantResponse.data.isExisting : null);
        this.setState({ merchantResponseValue: merchantResponse ? merchantResponse.data : null });
        console.log('VALIDATION 7', this.state.merchantResponseValue);
        // if (this.state.merchantResponseValue && merchantResponse.data.isExisting === true || tinValidation || mobileValidation || mobileValidation2 || !emailValidation || prefixValidation) {
        if ((this.state.merchantResponseValue && merchantResponse.data.isExisting === true) || tinValidation || mobileValidation || mobileValidation2 || !emailValidation) {
            this.setState({ openErrorDialog: true });
            this.setState({ isFetching: false });
        } else {
            this.setState({ openConfirmationDialog: true });
            this.setState({ isFetching: false });
            console.log('SHOWING ERROR');
        }
    }

    handleConfirmationDialog = () => {
        // console.log('MERCHANT VALUES', this.state.merchantResponseRaw);
        if (this.state.activeStep === 0) {
            const companyDetails = this.state.companyDetails;
            const checkData = {
                name: companyDetails.name,
                email: companyDetails.email,
                mobile_no: companyDetails.mobile_no,
                company_name: companyDetails.company_name,
                nature_of_business: companyDetails.nature_of_business,
                company_address: companyDetails.company_address,
                company_website: companyDetails.company_website,
                tin: companyDetails.tin,
                prefix: companyDetails.prefix,
                isExisting: true,
            }
            this.setState({ isFetching: true });
            this.proceed(checkData);
        }

    }

    formatTimer = () => {
        const minutes = Math.floor(this.state.time / 60);
        const seconds = this.state.time - minutes * 60;
        const formatSeconds = seconds < 10 ? `0${seconds}` : seconds;
        const fullTime = `${minutes}:${formatSeconds}`;
        // console.log('FULL TIME', fullTime);
        return fullTime;
    }

    componentDidUpdate() {
        if (this.state.time <= 0) {
            console.log('TIME ZERO');
            clearInterval(this.state.timer);
        }
    }

    componentDidMount() {
        this.setState({ activeStep: 1 });
    }

    resendOTP = () => {
        const otpResponse = this.state.otpResponseRaw ? this.state.otpResponseRaw.data : '';
        console.log('OTP RESPONSE AT RESEND', otpResponse);
        if (otpResponse) {
            const resendValue = {
                id: otpResponse.Id,
            };
            console.log('RESEND VALUE', resendValue);
            this.resendOtp(resendValue)
        } else {
            const mobileToSend = {
                mobile_no: this.state.companyDetails.mobile_no,
            };
            this.saveOtp(mobileToSend);
        }
        this.setState({
            time: 180,
        })
    }

    closeErrorDiag = () => {
        this.setState({ openErrorDialog: false });
    }

    handleChange = (name) => event => {
        // if (event.target.value === '0') {
        //     this.setState({ activeStep: this.state.activeStep + 1 });
        // }
        if (event.target.value.length >= 3) {
            this.setState({ invalidOtp: false });
        }
        if (name !== 'otp' && name !== 'mobile_no' && name !== 'tin') {
            this.setState({
                companyDetails: {
                    ...this.state.companyDetails,
                    ...{ [name]: event.target.value },
                },
            });
        }
        else {
            this.setState({ otp: event.target.value });
        }
        // console.log('COMPANY DETAILS VALUE', this.state.companyDetails);
    }

    render() {
        const { companyDetails, otp, invalidOtp, activeStep, isFetching, steps, setFormState, hideDialog, timer, merchantResponseValue, openConfirmationDialog, openErrorDialog } = this.state;
        // eslint-disable-next-line
        const emailLegit = companyDetails ? companyDetails.email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gim) : '';

        const disableNextOne =
            companyDetails.tin === '' ||
            companyDetails.name === '' ||
            companyDetails.email === '' ||
            companyDetails.mobile_no === '' ||
            companyDetails.company_name === '' ||
            companyDetails.company_address === '' ||
            companyDetails.company_website === '' ||
            companyDetails.nature_of_business === '';
        // console.log('DISBLE', disableNextOne);
        const disableNextTwo = otp === '';
        // if (this.props.openDialog === true && this.state.setFormState === true) {
        if (this.props.openDialog && !setFormState && !hideDialog) {
            this.showDialog();
        }
        if (activeStep === 2) {
            clearInterval(timer);
        }
        const fullTime = this.formatTimer();

        if (!isFetching && this.state.merchantRegistrationSent && this.state.merchantRegistrationSuccess) {
            this.setState({ isFetching: false });
            this.setState({ merchantRegistrationSent: false });
            this.setState({ activeStep: this.state.activeStep + 1 });
        }
        // console.log('TIMER CONDITION', timer === 0);
        // console.log('TIMER VALUE', timer);
        // console.log('CURRENT ACTIVE STEP', activeStep);
        console.log('WINDOW HEIGHT IS: ', document.documentElement.scrollHeight);
        console.log('WINDOW WIDTH IS: ', document.documentElement.scrollWidth);
        const merchantData = merchantResponseValue ? merchantResponseValue : '';
        const vertical = 'bottom';
        const horizontal = 'right';
        const screenWidth = document.documentElement.scrollWidth;
        const mobile = {
            maxWidth: '960px',
            margin: '15px',
        };
        return (
            <div>
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={isFetching}
                    // onClose={this.handleClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                        'className': "snackbarContent",
                    }}
                    message={
                        <span id="message-id">
                            <div className="snackBarMessageContainer">
                                <div className="snackBarMessage">Loading please wait...</div>
                                <div><CircularProgress size={30} /></div>
                            </div>
                        </span>}
                />
                <Dialog
                    className="dialogScroll"
                    onSubmit={this.handleSubmit}
                    open={setFormState}
                    maxWidth={"md"}
                    PaperProps={{
                        // 'className': "dialogPaper",
                        // classes:{{root: }}
                        // // className:'dialogPaper',
                        'style':
                        screenWidth <= '450' ? mobile : '',
                    }}
                    fullWidth={true}
                >
                    <div className="dialogDivScroll">
                        <Stepper
                            className="stepperClass"
                            activeStep={activeStep} alternativeLabel>
                            {steps.map((label, index) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step className="dialogScrollStep" key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                        {activeStep === 0 ?
                            <div className="dBoxSize">
                                <div className="dBoxForm">
                                    <DialogTitle className="dBoxTitle" id="form-dialog-title">Your Company Details</DialogTitle>
                                    <div className="pMargin">To register, Please fill up the required details below</div>
                                    <DialogContent
                                        className="dBoxFormContent"
                                    >
                                        <div>
                                            <TextField
                                                autoFocus
                                                margin="dense"
                                                id="name"
                                                label="Your Name"
                                                fullWidth
                                                tabIndex="0"
                                                inputProps={{ maxLength: 50 }}
                                                value={companyDetails.name}
                                                onChange={this.handleChange('name')}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                className="dBoxCompanyName"
                                                margin="dense"
                                                id="company_name"
                                                label="Your Company Name"
                                                fullWidth
                                                inputProps={{ maxLength: 24 }}
                                                value={companyDetails.company_name}
                                                onChange={this.handleChange('company_name')}
                                            />
                                        </div>
                                        <div>
                                            <NumberFormat
                                                className="dBoxTin"
                                                inputMode="numeric"
                                                label="Company Tin"
                                                onValueChange={(values) => {
                                                    const { formattedValue } = values;
                                                    this.setState({
                                                        companyDetails: {
                                                            ...this.state.companyDetails,
                                                            // eslint-disable-next-line no-useless-computed-key
                                                            ...{ ['tin']: formattedValue },
                                                        },
                                                    });
                                                }}
                                                format="###-###-###-###"
                                                customInput={TextField}
                                            />
                                            <TextField
                                                className="dBoxNOB"
                                                margin="dense"
                                                id="nature_of_business"
                                                label="Nature of Business"
                                                fullWidth
                                                inputProps={{ maxLength: 100 }}
                                                value={companyDetails.nature_of_business}
                                                onChange={this.handleChange('nature_of_business')}

                                            />
                                        </div>
                                        <div>
                                            <NumberFormat
                                                className="dBoxMobile"
                                                inputMode="numeric"
                                                placeholder="9XX-XXX-XXXX"
                                                label="Mobile Number"
                                                onValueChange={(values) => {
                                                    const { value } = values;
                                                    this.setState({
                                                        companyDetails: {
                                                            ...this.state.companyDetails,
                                                            // eslint-disable-next-line no-useless-computed-key
                                                            ...{ ['mobile_no']: '63' + value },
                                                        },
                                                    });
                                                }}
                                                format="###-###-####"
                                                customInput={TextField}
                                                InputProps={{
                                                    startAdornment:
                                                        <InputAdornment
                                                            position="start"
                                                        >
                                                            <div
                                                            >+63
                                                        </div>
                                                        </InputAdornment>,
                                                }}
                                            />
                                            <TextField
                                                className="dBoxEmail"
                                                margin="dense"
                                                id="email"
                                                label="Email"
                                                type="email"
                                                inputProps={{ maxLength: 50 }}
                                                fullWidth
                                                value={companyDetails.email}
                                                onChange={this.handleChange('email')}
                                            />
                                        </div>

                                        <TextField
                                            margin="dense"
                                            id="company_website"
                                            label="Company Website"
                                            inputProps={{ maxLength: 50 }}
                                            fullWidth
                                            value={companyDetails.company_website}
                                            onChange={this.handleChange('company_website')}
                                        />
                                        <TextField
                                            margin="dense"
                                            id="company_address"
                                            label="Company Address"
                                            fullWidth
                                            inputProps={{ maxLength: 200 }}
                                            value={companyDetails.company_address}
                                            onChange={this.handleChange('company_address')}

                                        />
                                    </DialogContent>
                                    <div>

                                    </div>
                                </div>
                                <div className="dBoxImg">
                                    <DialogContent>
                                        <div>
                                            <img
                                                height={'100%'}
                                                width={'100%'}
                                                className="imgSize"
                                                src={step1}
                                                alt="avatar"
                                            />
                                        </div>
                                    </DialogContent>
                                </div>
                            </div>
                            : activeStep === 1
                                ?

                                < div className="parentDiv" >

                                    <div className="parentText">
                                        <div style={{ width: '98%' }}>
                                            <DialogTitle id="form-dialog">OTP</DialogTitle>
                                            <p className="pMargin" >We've sent a one-time-password on the mobile number that you nominated on previous step. If you received the code via SMS, please enter the code here.</p>
                                            <img src={step2a} style={{ paddingLeft: '40px' }} alt="avatar2" />
                                        </div>
                                    </div>

                                    <div className="parentOTP">
                                        <DialogContent className="dialogCont">
                                            <div className="otpCont">
                                                <img src={step2b} alt="avatar2" />
                                                <div className="otpField">
                                                    <div className="txtOTPCont" >
                                                        <div className="txtOTP">
                                                            <strong>
                                                                <TextField
                                                                    className="otpTextField"
                                                                    // style={{ width: '207px', textAlign: 'center', fontSize: '10px'}}
                                                                    autoFocus
                                                                    margin="dense"
                                                                    id="otp"
                                                                    disabled={isFetching}
                                                                    label="ENTER YOUR OTP"
                                                                    type="number"
                                                                    onInput={(e) => {
                                                                        e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 6)

                                                                    }}
                                                                    fullWidth
                                                                    tabIndex="0"
                                                                    value={otp}
                                                                    onChange={this.handleChange('otp')}
                                                                />

                                                            </strong>
                                                        </div>
                                                    </div>
                                                    <div className="divErrorCont">
                                                        {invalidOtp ?
                                                            <div className="divError">
                                                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                                                    Invalid OTP, please try again.
                                                                </div>
                                                            :
                                                            <div className="divErrorEmpty"></div>
                                                        }
                                                    </div>
                                                    <div className="resendBtnCont">
                                                        <Button
                                                            className="resendBtn"
                                                            variant="outlined"
                                                            color="primary"
                                                            disabled={fullTime !== '0:00'}
                                                            onClick={this.resendOTP}
                                                        >
                                                            <div className="resendBtnLbl">
                                                                {
                                                                    fullTime !== '0:00' ?
                                                                        'Resend OTP in: ' + fullTime
                                                                        : 'Resend OTP'
                                                                }
                                                            </div>
                                                        </Button>
                                                    </div>

                                                </div>
                                            </div>
                                        </DialogContent>
                                    </div>
                                </div>
                                : activeStep === 2
                                    ?
                                    <div className="parentDiv">
                                        <div className="parentDivImage">

                                            <DialogTitle id="form-dialog">Registration Complete</DialogTitle>
                                            <div className="parentDivText1">Congratulations, you have successfully created a workflow account</div>
                                            <img src={step3} className="parentDivImg" alt="avatar" />
                                        </div>
                                        <div className="parentDivText">
                                            <div className="parentDivTextValue">
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>We have created 3 accounts for you. One as an administrator access, one for developer access and one for integrator access.</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>Use your administrator access to monitor your testing.</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>Use your developer access to view our online API documentation.</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>Use your integrator access to request to our API.</p>
                                                </div>
                                                <div style={{ display: 'flex', width: '100%' }}>
                                                    <img src={checkmark}
                                                        alt="check"
                                                        style={{ flex: 0.1, marginTop: '4px', height: '31px', marginRight: '17px' }} />
                                                    <p style={{ flex: 0.8 }}>We have sent email to {companyDetails.email} about the details of your workflow access</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''

                        }
                    </div>
                    <DialogActions className="moreActionDialog">
                        <div className="footerBase">
                            <div className="footerDiv">
                                {activeStep === 0 ?
                                    <Button onClick={activeStep === 0 ? this.hideDialog : ''} disabled={isFetching}>
                                        {'Exit'}
                                    </Button>
                                    : ''
                                }
                                <Button
                                    disabled={isFetching ? isFetching : activeStep === 0 ? disableNextOne : activeStep === 1 ? disableNextTwo : false}
                                    variant="contained"
                                    color="primary"
                                    onClick={activeStep === 0 ? this.handleConfirmationDialog : activeStep === 1 ? this.handleNext : activeStep === 2 ? this.hideDialog : ''}
                                >
                                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openConfirmationDialog}
                >
                    <DialogTitle id="form-dialog-title">CONFRIMATION</DialogTitle>
                    <DialogContent>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Name
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Company Name
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.company_name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        TIN
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.tin}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Nature of Business
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.nature_of_business}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Mobile Number
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.mobile_no}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Email
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.email}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Company Website
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.company_website}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        Company Address
                                    </TableCell>
                                    <TableCell>
                                        {companyDetails.company_address}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.hideConfirmDialog}
                        >
                            CANCEL
                        </Button>
                        <Button
                            onClick={this.handleNext}
                            variant="contained"
                            color="primary"
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    className="errorDialog"
                    maxWidth={"sm"}
                    // disableBackdropClick={true}
                    open={openErrorDialog}
                >
                    <DialogContent>
                        {companyDetails && companyDetails.tin.length !== 15 ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                TIN must have 12 characters.
                            </div>
                            : ''
                        }
                        {companyDetails && companyDetails.mobile_no.substring(0, 3) !== '639' ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                {'Invalid mobile number format.\nMobile number should start with 639'}
                            </div>
                            : ''
                        }
                        {companyDetails && companyDetails.mobile_no.length !== 12 ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Mobile No must have 12 characters.
                            </div>
                            : ''
                        }
                        {!emailLegit ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Invalid email.
                            </div>
                            : ''
                        }

                        {merchantData && merchantData.tin ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                TIN Already Exist
                            </div>
                            : ''
                        }

                        {merchantData && merchantData.companyEmail ?
                            <div className="divErrorDetails">
                                <ErrorOutlineOutlinedIcon className="iconStyle" />
                                Email Already Exist
                            </div>
                            : ''
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.closeErrorDiag}
                            variant="contained"
                            color="primary"
                        >
                            OK
                    </Button>
                    </DialogActions>


                </Dialog>

            </div >
        )
    }
}
export default FormStepperComponent